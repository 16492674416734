import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/pages/Home";
import Transporters from "./components/pages/Transporters";
import Truckers from "./components/pages/Truckers";
import ContactUs from "./components/pages/ContactUs";
import Footer from "./components/Footer";
import PrivacyandPolicy from "./components/pages/PrivacyandPolicy";
import TermsConditions from "./components/pages/Terms&Conditions";
import ComingSoon from "./components/pages/ComingSoon";
import NotFound from "./components/pages/404Error";
import { AnimatePresence } from 'framer-motion';

const MainContent = () => {
  const location = useLocation();

  const isNotFoundPage = location.pathname === "/404";

  return (
    <>
      {!isNotFoundPage && <Navbar />}
      <main className="main-content">
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/transporters" element={<Transporters />} />
            <Route path="/truckers" element={<Truckers />} />
            <Route path="/aboutus" element={<ComingSoon />} />
            <Route path="/news" element={<ComingSoon />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/privacyandpolicy" element={<PrivacyandPolicy />} />
            <Route path="/termsandconditions" element={<TermsConditions />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
            <Route path="/404" element={<NotFound />} />
          </Routes>
        </AnimatePresence>
      </main>
      {!isNotFoundPage && <Footer />}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <MainContent />
    </Router>
  );
};

export default App;
