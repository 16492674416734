import React from 'react';
import '../css/ComingSoon.css';  // Import the CSS file
import {motion} from 'framer-motion';

const ComingSoon = () => {
  return (
    <motion.div className="coming-soon-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: {duration: 0.2}}}>
      <h1 className="coming-soon-text">Coming Soon<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></h1>
    </motion.div>
  );
};

export default ComingSoon;
