import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import '../css/Truckers.css';
import TruckGif from '../../assets/video/truck gif.gif';
import Trucker from '../../assets/img/trucker.jpg';

const cities = [
    "Ahmedabad", "Aizawl", "Akola", "Amalner", "Amravati", "Amritsar", "Anand", "Anantapur",
    "Anakapalle", "Aurangabad", "Azamgarh", "Baharampur", "Bagaha", "Balaghat", "Balurghat",
    "Baleshwar Town", "Barnala", "Baramula", "Baripada Town", "Barmer", "Bharatpur", "Bharuch",
    "Bhilai Nagar", "Bhilai", "Bhilwara", "Bhopal", "Bhuj", "Bhagalpur", "Bhadrak", "Bilaspur",
    "Bina", "Biraul", "Bokaro Steel City", "Bombay", "Brahmapur", "Chandausi", "Chandigarh",
    "Chilakaluripet", "Chittoor", "Coimbatore", "Cuttack", "Deesa", "Deoghar", "Dhanbad",
    "Dantewada", "Darbhanga", "Dimapur", "Dibrugarh", "Durg", "Dhule", "Dharmavaram", "Etawah",
    "Firozabad", "Firozpur", "Faridabad", "Faridkot", "Ganjbasoda", "Gandhinagar", "Guntur",
    "Gondia", "Gulbarga", "Gurgaon", "Guwahati", "Habra", "Hajipur", "Haldwani-cum-Kathgodam",
    "Hansi", "Hisar", "Hazaribag", "Hugli-Chinsurah", "Ichalkaranji", "Imphal", "Indore",
    "Jabalpur", "Jaisalmer", "Jammu", "Jamshedpur", "Jalgaon", "Jalpaiguri", "Jalandhar",
    "Jhansi", "Jhumri Tilaiya", "Jind", "Jorhat", "Jodhpur", "Kadapa", "Kakinada", "Kalyan-Dombivali",
    "Kamptee", "Kancheepuram", "Karimnagar", "Kashipur", "Kolkata", "Kolar", "Korba", "Kothagudem",
    "Kurnool", "Kozhikode", "Latur", "Lakhisarai", "Lakhimpur", "Ludhiana", "Loni", "Lucknow",
    "Madhubani", "Madurai", "Mahabubnagar", "Maharajganj", "Mangalore", "Mysore", "Madanapalle",
    "Malerkotla", "Mandya", "Mangaluru", "Mandsaur", "Marmagao", "Medinipur", "Muzaffarpur",
    "Moradabad", "Morvi", "Nagapattinam", "Nagda", "Nagpur", "Nanded", "Nanded-Waghala", "Nashik",
    "Nellore", "Neyveli (TS)", "New Delhi", "Noida", "Nagarcoil", "Navi Mumbai", "Ongole", "Orai",
    "Palakkad", "Pali", "Panchkula", "Panaji", "Panipat", "Parbhani", "Patan", "Pathankot",
    "Pudukkottai", "Purnia", "Purulia", "Raayachuru", "Rae Bareli", "Rajkot", "Rajampet",
    "Rajasthan", "Rajnandgaon", "Ramgarh", "Rampur", "Ranchi", "Ratlam", "Rewari", "Rudrapur",
    "Salem", "Sambalpur", "Sambhal", "Sangli", "Satna", "Saurashtra", "Siliguri", "Srinagar",
    "Shillong", "Shimla", "Shivamogga", "Shivpuri", "Sitapur", "Sitamarhi", "Suryapet",
    "Tadepalligudem", "Tadpatri", "Theni Allinagaram", "Thiruvananthapuram", "Tiruchirappalli",
    "Tirupati", "Tumkur", "Udhagamandalam", "Udaipur", "Udupi", "Ujjain", "Vadodara", "Valsad",
    "Varanasi", "Vapi", "Vellore", "Viluppuram", "Visakhapatnam", "Warangal", "Washim",
    "Wardha", "Yavatmal", "Yemmiganur", "Yamunanagar", "Zirakpur"
];

const containerTypes = [
    "22 Ft Container", "24 Ft Container", "32 Ft SXL Container", "32 Ft MXL Container", "34 Ft Container"
];

function Truckers() {
    const [truckerName, setTruckerName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');

    const [loadFromCity, setLoadFromCity] = useState('');
    const [loadFromCityOptions, setLoadFromCityOptions] = useState([]);

    const [loadToCity, setLoadToCity] = useState('');
    const [loadToCityOptions, setLoadToCityOptions] = useState([]);

    const [currentCity, setCurrentCity] = useState('');
    const [currentCityOptions, setCurrentCityOptions] = useState([]);

    const [containerType, setContainerType] = useState('');
    const [containerTypeOptions, setContainerTypeOptions] = useState([]);

    const [capacity, setCapacity] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const [showLoadFromCityDropdown, setShowLoadFromCityDropdown] = useState(false);
    const [showToCityDropdown, setShowToCityDropdown] = useState(false);
    const [showCurrentCityDropdown, setShowCurrentCityDropdown] = useState(false);
    const [showContainerTypeDropdown, setShowContainerTypeDropdown] = useState(false);

    const [buttonText, setButtonText] = useState('Send'); // Button state

    const loadFromCityDropdownRef = useRef(null);
    const loadToCityDropdownRef = useRef(null);
    const currentCityDropdownRef = useRef(null);
    const containerTypeDropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                loadFromCityDropdownRef.current && !loadFromCityDropdownRef.current.contains(event.target) &&
                loadToCityDropdownRef.current && !loadToCityDropdownRef.current.contains(event.target) &&
                currentCityDropdownRef.current && !currentCityDropdownRef.current.contains(event.target) &&
                containerTypeDropdownRef.current && !containerTypeDropdownRef.current.contains(event.target)
            ) {
                closeAllDropdowns();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCitySearch = (input, setOptions, options, setShowDropdown) => {
        const filteredOptions = options.filter(option => option.toLowerCase().includes(input.toLowerCase()));
        setOptions(filteredOptions);
        setShowDropdown(true);
    };

    const handleOptionSelect = (option, setOption, setShowDropdown) => {
        setOption(option);
        setShowDropdown(false);
    };

    const closeAllDropdowns = () => {
        setShowLoadFromCityDropdown(false);
        setShowToCityDropdown(false);
        setShowCurrentCityDropdown(false);
        setShowContainerTypeDropdown(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonText("Sending..."); // Update button text to "Sending..."

        const loadingData = {
            truckerName,
            phoneNumber,
            email,
            loadFromCity,
            loadToCity,
            currentCity,
            containerType,
            capacity,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_POST_URL}/api/loadings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loadingData),
            });

            if (response.ok) {
                setModalMessage('Your Load has been submitted successfully!');
                setIsError(false);
            } else {
                setModalMessage('Failed to submit Load.');
                setIsError(true);
            }

            setShowModal(true);
            // Reset form fields after successful submission
            resetForm();
        } catch (error) {
            console.error('Error submitting booking:', error);
            setModalMessage('An error occurred while submitting your Load.');
            setShowModal(true);
            setIsError(true);
        }

        setButtonText("Send"); // Reset button text to "Submit"
    };

    const resetForm = () => {
        setTruckerName('');
        setPhoneNumber('');
        setEmail('');
        setLoadFromCity('');
        setLoadToCity('');
        setCurrentCity('');
        setContainerType('');
        setCapacity('');
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <motion.div className='trucker-containers' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: { duration: 0.2 } }}>
            <div className='image-container'>
                <img src={Trucker} alt='Truckers'></img>
            </div>
            <h1 className="heading-one animate__animated animate__fadeInUp">Reliable Loads,</h1>
            <h1 className="heading-two animate__animated animate__fadeInUp">More Profits</h1>
            <p className="paragraph">GOTL ensures higher earnings on every trip by providing consistent loads and timely payment settlements, all with zero commission fees.</p>

            <div className="trucker-button-load">
                <a href='#truckers' className="trucker-load-truck">Find a Load</a>
            </div>

            <section className='trucker-main-container' id='truckers'>
                <div className="trucker-form-container">
                    <h2 className="trucker-title">Load Your Truck</h2>
                    <form onSubmit={handleSubmit} className="trucker-form-main">
                        <div className="trucker-form-group">
                            <input
                                className="trucker-input"
                                id="truckerName"
                                type="text"
                                placeholder="Trucker Name"
                                value={truckerName}
                                onChange={(e) => setTruckerName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="trucker-form-group">
                            <input
                                className="trucker-input"
                                id="phoneNumber"
                                type="tel"
                                placeholder="Phone Number"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                minLength={10}
                                maxLength={10}
                                required
                            />
                        </div>
                        <div className="trucker-form-group">
                            <input
                                className="trucker-input"
                                id="email"
                                type="email"
                                placeholder="Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>

                        {/* Load From City Dropdown */}
                        <div className="trucker-form-group searchable-select" ref={loadFromCityDropdownRef}>
                            <input
                                className="dropdown-input"
                                type="text"
                                placeholder="Load From City"
                                value={loadFromCity}
                                onChange={(e) => {
                                    setLoadFromCity(e.target.value);
                                    handleCitySearch(e.target.value, setLoadFromCityOptions, cities, setShowLoadFromCityDropdown);
                                }}
                                onFocus={() => {
                                    closeAllDropdowns();
                                    setLoadFromCityOptions(cities); // Show all cities when input is focused
                                    setShowLoadFromCityDropdown(true);
                                }}
                                required
                            />
                            {showLoadFromCityDropdown && (
                                <div className="dropdown-list">
                                    {loadFromCityOptions.map(city => (
                                        <div
                                            key={city}
                                            className="dropdown-item"
                                            onClick={() => handleOptionSelect(city, setLoadFromCity, setShowLoadFromCityDropdown)}
                                        >
                                            {city}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Load To City Dropdown */}
                        <div className="trucker-form-group searchable-select" ref={loadToCityDropdownRef}>
                            <input
                                className="dropdown-input"
                                type="text"
                                placeholder="Load To City"
                                value={loadToCity}
                                onChange={(e) => {
                                    setLoadToCity(e.target.value);
                                    handleCitySearch(e.target.value, setLoadToCityOptions, cities, setShowToCityDropdown);
                                }}
                                onFocus={() => {
                                    closeAllDropdowns();
                                    setLoadToCityOptions(cities); // Show all cities when input is focused
                                    setShowToCityDropdown(true);
                                }}
                                required
                            />
                            {showToCityDropdown && (
                                <div className="dropdown-list">
                                    {loadToCityOptions.map(city => (
                                        <div
                                            key={city}
                                            className="dropdown-item"
                                            onClick={() => handleOptionSelect(city, setLoadToCity, setShowToCityDropdown)}
                                        >
                                            {city}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Current City Dropdown */}
                        <div className="trucker-form-group searchable-select" ref={currentCityDropdownRef}>
                            <input
                                className="dropdown-input"
                                type="text"
                                placeholder="Current City"
                                value={currentCity}
                                onChange={(e) => {
                                    setCurrentCity(e.target.value);
                                    handleCitySearch(e.target.value, setCurrentCityOptions, cities, setShowCurrentCityDropdown);
                                }}
                                onFocus={() => {
                                    closeAllDropdowns();
                                    setCurrentCityOptions(cities);
                                    setShowCurrentCityDropdown(true);
                                }}
                                required
                            />
                            {showCurrentCityDropdown && (
                                <div className="dropdown-list">
                                    {currentCityOptions.map(city => (
                                        <div
                                            key={city}
                                            className="dropdown-item"
                                            onClick={() => handleOptionSelect(city, setCurrentCity, setShowCurrentCityDropdown)}
                                        >
                                            {city}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        
                        {/* Container Type Dropdown */}
                        <div className="trucker-form-group searchable-select" ref={containerTypeDropdownRef}>
                            <input
                                className="dropdown-input"
                                type="text"
                                placeholder="Container Type"
                                value={containerType}
                                onChange={(e) => {
                                    setContainerType(e.target.value);
                                    handleCitySearch(e.target.value, setContainerTypeOptions, containerTypes, setShowContainerTypeDropdown);
                                }}
                                onFocus={() => {
                                    closeAllDropdowns();
                                    setContainerTypeOptions(containerTypes);
                                    setShowContainerTypeDropdown(true);
                                }}
                                required
                            />
                            {showContainerTypeDropdown && (
                                <div className="dropdown-list">
                                    {containerTypeOptions.map(type => (
                                        <div
                                            key={type}
                                            className="dropdown-item"
                                            onClick={() => handleOptionSelect(type, setContainerType, setShowContainerTypeDropdown)}
                                        >
                                            {type}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Capacity Input */}
                        <div className="trucker-form-group">
                            <input
                                className="trucker-input"
                                id="capacity"
                                type="number"
                                placeholder="Capacity (Tons)"
                                value={capacity}
                                onChange={(e) => setCapacity(e.target.value)}
                                min={1}
                                max={25}
                                required
                            />
                        </div>

                        <button className="trucker-button" type="submit"><span>{buttonText}</span></button>
                    </form>
                </div>

                <div className='gif'>
                    <img src={TruckGif} alt="Truck GIF" className='truck-gif'></img>
                </div>

                {/* Modal for success or error message */}
                {showModal && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h3>{isError ? 'Error' : 'Success'}</h3>
                            <p>{modalMessage}</p>
                            <button className="modal-close-button" onClick={handleCloseModal}>Close</button>
                        </div>
                    </div>
                )}
            </section>
        </motion.div>
    );
};

export default Truckers;
