import React, { useEffect } from 'react';
import '../css/404Error.css';
import '../css/FontAwesome.css';
import BG from '../../assets/img/404.avif';

const ErrorPage = () => {
  useEffect(() => {
    const animate = () => {
      let lFollowX = 0,
        lFollowY = 0,
        x = 0,
        y = 0,
        friction = 1 / 30;

      const moveBackground = () => {
        x += (lFollowX - x) * friction;
        y += (lFollowY - y) * friction;

        const translate = `translate(${x}px, ${y}px) scale(1.1)`;

        document.querySelector('.error-page-bg').style.transform = translate;

        window.requestAnimationFrame(moveBackground);
      };

      window.addEventListener('mousemove', (e) => {
        const lMouseX = Math.max(-100, Math.min(100, window.innerWidth / 2 - e.clientX));
        const lMouseY = Math.max(-100, Math.min(100, window.innerHeight / 2 - e.clientY));
        lFollowX = (20 * lMouseX) / 100;
        lFollowY = (10 * lMouseY) / 100;
      });

      moveBackground();
    };

    animate();
  }, []);

  return (
    <div className="error-page w3l-error-block">
      <div className="error-page-content page">
        <div className="error-page-content-wrapper content">
          <div className="error-page-logo logo">
            {/* Uncomment and replace 'image-path' with your logo image path if needed */}
            {/* <a className="brand-logo" href="#index.html">
              <img src="image-path" alt="Your logo" title="Your logo" style={{ height: '35px' }} />
            </a> */}
          </div>
          <div className="error-page-grid w3l-error-grid">
            <h1 className="error-page-heading">404</h1>
            <h2 className="error-page-subheading">Page not found</h2>
            <p className="error-page-message">I tried to catch some trucks, but I missed it</p>
            <a href="/" className="error-page-home-link home">Back to Home</a>
          </div>
          <div className="error-page-copyright copy-right text-center">
            <p>© 2024 Go Trans Logistics. All rights reserved.</p>
          </div>
        </div>
        <img src={BG} className="error-page-bg img-responsive" alt="error" />
      </div>
    </div>
  );
};

export default ErrorPage;
