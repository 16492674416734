// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
 box-sizing: border-box;
 padding: 0;
 margin: 0;
}

:root {
 --header-height: 3.5rem;
 --first-color: hsl(28, 88%, 62%);
 --title-color: hsl(0, 0%, 95%);
 --text-color: hsl(0, 0%, 84%);
 --body-color: rgb(245, 245, 245); /*linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);  */
 --body-font: "Montserrat", sans-serif;
 --second-font: "Dancing Script", cursive;
 --h2-font-size: 1.25rem;
 --small-font-size: 0.813rem;
 --font-semi-bold: 600;
 --z-tooltip: 10;
 --z-fixed: 100;
}

body {
 background-color: var(--body-color);
 color: var(--text-color);
 font-family: var(--body-font);
}

ul {
 list-style: none;
}

a {
 text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;CACC,sBAAsB;CACtB,UAAU;CACV,SAAS;AACV;;AAEA;CACC,uBAAuB;CACvB,gCAAgC;CAChC,8BAA8B;CAC9B,6BAA6B;CAC7B,gCAAgC,EAAE,gEAAgE;CAClG,qCAAqC;CACrC,wCAAwC;CACxC,uBAAuB;CACvB,2BAA2B;CAC3B,qBAAqB;CACrB,eAAe;CACf,cAAc;AACf;;AAEA;CACC,mCAAmC;CACnC,wBAAwB;CACxB,6BAA6B;AAC9B;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,qBAAqB;AACtB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap\");\n\n* {\n box-sizing: border-box;\n padding: 0;\n margin: 0;\n}\n\n:root {\n --header-height: 3.5rem;\n --first-color: hsl(28, 88%, 62%);\n --title-color: hsl(0, 0%, 95%);\n --text-color: hsl(0, 0%, 84%);\n --body-color: rgb(245, 245, 245); /*linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);  */\n --body-font: \"Montserrat\", sans-serif;\n --second-font: \"Dancing Script\", cursive;\n --h2-font-size: 1.25rem;\n --small-font-size: 0.813rem;\n --font-semi-bold: 600;\n --z-tooltip: 10;\n --z-fixed: 100;\n}\n\nbody {\n background-color: var(--body-color);\n color: var(--text-color);\n font-family: var(--body-font);\n}\n\nul {\n list-style: none;\n}\n\na {\n text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
