import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Truck from "../../assets/img/Trucks.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import "../css/ContactUs.css";
import { motion } from 'framer-motion';

function ContactUs() {
    const formInitialDetails = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: ''
    };

    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState('Send');
    const [showModal, setShowModal] = useState(false); // State to manage modal visibility
    const [modalMessage, setModalMessage] = useState('');
    const [isError, setIsError] = useState(false); // State to check for success or error

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        });
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setIsError(false); // Reset error state after modal closes
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonText("Sending...");
        let response = await fetch(`${process.env.REACT_APP_POST_URL}/contact`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(formDetails),
        });
        setButtonText("Send");
        let result = await response.json();
        setFormDetails(formInitialDetails);

        if (result.code === 200) {
            setModalMessage('Message sent successfully.');
            setIsError(false);
        } else {
            setModalMessage('Something went wrong, please try again later.');
            setIsError(true);
        }

        setShowModal(true); // Show modal on success or error
    };

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: { duration: 0.2 } }}>
            <section className="contact" id="connect">
                <Container>
                    <Row className="align-items-center">
                        <Col size={12} md={6}>
                            <TrackVisibility>
                                {({ isVisible }) =>
                                    <img className={isVisible ? "animate__animated animate__zoomIn" : ""} src={Truck} alt="Contact Us" />
                                }
                            </TrackVisibility>
                        </Col>
                        <Col size={12} md={6}>
                            <div className="animate__animated animate__fadeIn">
                                <h2>Get In Touch</h2>
                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col size={12} sm={6} className="px-1">
                                            <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} required />
                                        </Col>
                                        <Col size={12} sm={6} className="px-1">
                                            <input type="text" value={formDetails.lastName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} required />
                                        </Col>
                                        <Col size={12} sm={6} className="px-1">
                                            <input type="email" value={formDetails.email} placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} required />
                                        </Col>
                                        <Col size={12} sm={6} className="px-1">
                                            <input type="tel" value={formDetails.phone} placeholder="Phone Number" onChange={(e) => onFormUpdate('phone', e.target.value)} maxLength={10} required />
                                        </Col>
                                        <Col size={12} className="px-1">
                                            <textarea rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)} required ></textarea>
                                            <button type="submit"><span>{buttonText}</span></button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Modal for success or error message */}
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>{isError ? 'Error' : 'Success'}</h3>
                        <p>{modalMessage}</p>
                        <button className="modal-close-button" onClick={handleCloseModal}>Close</button>
                    </div>
                </div>
            )}

            <p className="subhead">Note:- For any feedback or query please fill the contact form above.</p>
        </motion.div>
    );
}

export default ContactUs;
