import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import logo from "../assets/img/logo-black.png"
import "./css/Navbar.css";

function Navbar() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };


  return (
    <header className="header">
      <nav className="nav container">
        <NavLink to="/" className="nav__logo">
          <img src={logo} alt="logo" className="logo"></img>
        </NavLink>
        
        <div className={`nav__menu ${showMenu ? "show-menu" : ""}`} id="nav-menu">
          <ul className="nav__list">
            <li className="nav__item">
              <NavLink to="/home" className="nav__link" onClick={toggleMenu}>
                Home
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/transporters" className="nav__link" onClick={toggleMenu}>
                Transporters
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/truckers" className="nav__link" onClick={toggleMenu}>
                Truckers
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/aboutus" className="nav__link" onClick={toggleMenu}>
                About Us
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/news" className="nav__link" onClick={toggleMenu}>
                News
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/contactus" className="nav__link" onClick={toggleMenu}>
                Contact Us
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/transporters" className="nav__cta">
                Book a Truck
              </NavLink>
            </li>
          </ul>
          <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>

        <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
          <IoMenu />
        </div>
      </nav>
    </header>
  );
}

export default Navbar;




