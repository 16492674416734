import React from 'react';
import logo from "../assets/img/logo.png";
import { NavLink } from "react-router-dom";
import { FaPhone, FaEnvelope, FaLinkedin, FaInstagram, FaFacebook,FaLocationArrow } from "react-icons/fa";

import './css/Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-content">
                    <div className='logo-component'>
                        <img src={logo} className="footer-logo" alt='logo' />
                        <p className='slogan'>A new wave of logistics solutions, designed specifically for varying container needs - 20 Ft, 22 Ft, 32 Ft SXL, and 32 Ft MXL</p>
                    </div>
                    <div className="footer-nav">
                        <div className='important-content'>
                            <h3>Contact</h3>
                            <p><FaPhone /> +91 9693094957</p>
                            <a href='mailto:sales@gotranslogistics.in'><FaEnvelope /> sales@gotranslogistics.in</a>
                            <p><FaLocationArrow /> Office No. 1A, 3rd floor, 109/A/19 Girish Ghosh Road (Belur), Howrah 711202, West-Bengal</p>
                        </div>
                        <div className='important-content'>
                            <h3>Follow Us</h3>
                            <a href='https://www.linkedin.com/company/go-trans-logistics'><FaLinkedin /> LinkedIn</a>
                            <a href='https://www.instagram.com/gotranslogistics.in/'><FaInstagram /> Instagram</a>
                            <a href='/'><FaFacebook/> Facebook</a>
                        </div>
                        <div className='important-content'>
                            <h3>Legal</h3>
                            <NavLink to="/privacyandpolicy">Privacy and Policy</NavLink>
                            <NavLink to="/termsandconditions">Terms of Service</NavLink>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; 2024 Go Trans Logistics. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
