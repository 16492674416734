import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import '../css/Home.css';
import 'animate.css';
import { BsCashCoin } from "react-icons/bs";
import { MdSupportAgent, MdVerified } from "react-icons/md";
import { FaMapLocationDot } from "react-icons/fa6";
import TrackVisibility from 'react-on-screen';
import { motion } from 'framer-motion';
import Intro from '../../assets/video/intro.mp4';
import TwentyFT from '../../assets/img/20ft.png';
import TwentyTwoFT from '../../assets/img/22ft.png';
import ThirtyTwoFTSXL from '../../assets/img/sxl.png';
import ThirtyTwoFTMXL from '../../assets/img/mxl.png';
import Loc from '../../assets/img/route.png';
import Load from '../../assets/img/load.png';
import Driver from '../../assets/img/driver.png';
import Person from '../../assets/img/person.png';

function Home() {
  const scrollRef = useRef(null);

  // Infinite scroll effect
  useEffect(() => {
    const scrollContainer = scrollRef.current;

    if (scrollContainer) {
      const scrollWidth = scrollContainer.scrollWidth;
      let scrollPos = 0;

      const scrollStep = () => {
        if (scrollPos >= scrollWidth / 2) {
          scrollPos = 0;
        } else {
          scrollPos += 1; // Adjust the scroll speed here
        }
        scrollContainer.scrollLeft = scrollPos;
      };

      const interval = setInterval(scrollStep, 10); // Adjust speed by changing the interval time

      return () => clearInterval(interval); // Clean up interval on component unmount
    }
  }, []);

  return (
    <motion.div className="container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: { duration: 0.2 } }}>
      <div className="home-video-container">
        <video autoPlay loop muted playsInline>
          <source src={Intro} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <h1 className="home-heading animate__animated animate__fadeInUp">A WAY TO NEW DIGITAL FLEET BROKERAGE</h1>

      <div className="Button-load">
        <NavLink to={"/transporters"}>
          <button className="book-truck"><span>Book a Truck</span></button>
        </NavLink>

        <NavLink to={"/truckers"}>
          <button className="find-load"><span>Find a Load</span></button>
        </NavLink>
      </div>

      <div className="home-content">
        <TrackVisibility>
          {({ isVisible }) => (
            <div className={isVisible ? 'first-content animate__animated animate__fadeInLeft' : ''}>
              <h2>India's only growing fastest Digital Freight Network</h2>
              <ul className="list-content">
                <li className="item-lists">Budget friendly freight among the market</li>
                <li className="item-lists">Capitalized by technology</li>
                <li className="item-lists">Easy Booking by just one click or one call</li>
                <li className="item-lists">24/7 customer support</li>
                <li className="item-lists">Safe Delivery all across India</li>
              </ul>
            </div>
          )}
        </TrackVisibility>

        <div className="second-content animate__animated animate__fadeInRight">

          <h2>Emerging Leaders in 20 Ft | 22 Ft | 32 Ft SXL | 32 Ft MXL Containers.</h2>
          <div className="fade-left"></div>
          <div className="scroll-container" ref={scrollRef}>
            <img className="image" src={TwentyFT} alt="20ft container" />
            <img className="image" src={TwentyTwoFT} alt="22ft container" />
            <img className="image" src={ThirtyTwoFTSXL} alt="32ft sxl container" />
            <img className="image" src={ThirtyTwoFTMXL} alt="32ft mxl container" />
            {/* Cloned images */}
            <img className="image" src={TwentyFT} alt="20ft container" />
            <img className="image" src={TwentyTwoFT} alt="22ft container" />
            <img className="image" src={ThirtyTwoFTSXL} alt="32ft sxl container" />
            <img className="image" src={ThirtyTwoFTMXL} alt="32ft mxl container" />
          </div>
          <div className="fade-right"></div>
        </div>

        <div className='fourth-content'>
          <h2>Verified Trucks,</h2>
          <h2>Reduced Shipping Cost & More Visibility</h2>
        </div>

        <div className='fifth-content'>
          <div className="content-one">
            <div className='box-1'>
              <h2>Route Optimization: </h2>
              <p className='description'>Implementing advanced route optimization algorithms can help fleet owners reduce fuel consumption, lower emissions, and decrease labor costs. By analyzing traffic patterns, road conditions, and delivery schedules, fleet owners can optimize routes to minimize unnecessary detours and reduce overall shipping costs.</p>
            </div>
            <TrackVisibility>
              {({ isVisible }) => (
                <img className={isVisible ? "route animate__animated animate__fadeInLeft" : " "} alt='Route' src={Loc}></img>
              )}
            </TrackVisibility>
          </div>
            
          <div className="content-two">  
            <div className='box-2'>
              <h2>Load Consolidation:</h2>
              <p className='description'>Consolidating shipments can help fleet owners reduce the number of vehicles on the road, resulting in lower fuel costs, reduced labor expenses, and decreased wear and tear on vehicles.</p>
            </div>
            <TrackVisibility>
              {({ isVisible }) => (
                <img className={isVisible ? "load animate__animated animate__fadeInRight" : " "} alt='Route' src={Load}></img>
              )}
            </TrackVisibility>
            </div>
          </div>  

        <div className="parent-sixth">
          <TrackVisibility>
            {({ isVisible }) => (
              <img src={Driver} alt="Driver" className={isVisible ? "driver animate__animated animate__fadeInLeft" : " driver"}></img>
            )}
          </TrackVisibility>
          <div className="sixth-content">
            <ul className="list-content">
              <li className="item-lists">We provide Consistent Loads,</li>
              <li className="item-lists">Lesser Halting to Maximize Earnings</li>
              <li className="item-lists">Go trans enables you to earn more regularly with minimized halting</li>
              <li className="item-lists">Zero percent commission from truck owner.</li>
              <li className="item-lists">On-time payment settlement.</li>
              <li className="item-lists">Enabling Truckers & Transporters to transact Transparently, Efficiently and Profitably.</li>
            </ul>
          </div>
        </div>


        <div className="parent-seventh">
          <TrackVisibility>
            {({ isVisible }) => (
              <img src={Person} alt="Truck Driver" className={isVisible ? "person animate__animated animate__fadeInRight" : ""}></img>
            )}
          </TrackVisibility>
          <div className="seventh-content">
            <img src="https://www.garykirstencricketindia.in/wp-content/uploads/2020/07/GKCIPresence-Map-1.png" alt="Map" className="map"></img>
            <div className="description">
              <h2>Transporters: 700+ transporters</h2>
              <h2>Truckers: 6000+ truckers</h2>
            </div>
          </div>
        </div>

        <div className='third-content animate__animated animate__fadeInDown'>
          <div className='column-1'>
            <h4 className='title'>Competitive Pricing</h4>
            <p className='description'>Get the best rate from market place you bid and get the load ready.</p>
            <BsCashCoin size={80} style={{ marginTop: '20px' }} />
          </div>

          <div className='column-2'>
            <h4 className='title'>24/7 Support</h4>
            <p className='description'>Get 24/7 customer support for any on route discrepancy.</p>
            <MdSupportAgent size={80} style={{ marginTop: '20px' }} />
          </div>

          <div className='column-3'>
            <h4 className='title'>Verified Truck Discovery</h4>
            <p className='description'>KYC verified truckers available PAN india to connect with transporters</p>
            <MdVerified size={80} style={{ marginTop: '20px' }} />
          </div>

          <div className='column-4'>
            <h4 className='title'>Real Time Tracking</h4>
            <p className='description'>GPS enabled vehicle support to justify real time tracking and safe delivery of shipment.</p>
            <FaMapLocationDot size={80} style={{ marginTop: '20px' }} />
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default Home;
