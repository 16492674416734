import React from 'react';
import '../css/Terms&Conditions.css';
import { motion } from 'framer-motion';

function TermsConditions() {
    return (
        <motion.div className="terms-conditions-container" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: {duration: 0.2}}}>
            <div className="terms-conditions-content">
                <h1 className="terms-conditions-heading">Terms of Service</h1>
                <h2 className="terms-conditions-subheading">Payment</h2>
                <p className="terms-conditions-paragraph">
                    The use of the Application and the Service is subject to subscription and transaction-based charges/fees. The applicable charges/fees will vary based on your registration type, such as transporter or trucker, and may change from time to time. The current charges/fees are outlined in Annexure A, and TruckCentral reserves the right to adjust these charges/fees periodically based on market conditions.
                </p>

                <h2 className="terms-conditions-subheading">Cancellation Policy</h2>
                <p className="terms-conditions-paragraph">
                    As GOTL deducts money from the wallet only at the end of the service, trucks booked through GOTL cannot be canceled. However, if either party is unable to fulfill their commitment (e.g., the trucker cannot send the truck or the transporter cannot honor the request), they may email <a href='mailto:sales@gotranslogistics.in'>sales@gotranslogistics.in</a> to request cancellation within 30 minutes of confirmation.

                    GOTL reserves the right to block truckers or transporters who fail to honor their commitments in accordance with GOTL policy.

                    For cancellations of Device Subscriptions, truckers can email <a href='mailto:sales@translogistics.in'>sales@gotranslogistics.in</a>. Refunds will be processed according to the refund policy.
                </p>

                <h2 className="terms-conditions-subheading">Refund Policy</h2>
                <p className="terms-conditions-paragraph">‍Customers can only request for refund in the following events</p>
                <p className="terms-conditions-paragraph">For Device Subscription cancellations, TruckCentral will issue a prorated refund, minus at least one month's subscription charge.

                For refunds of unutilized wallet amounts, customers must send a request via email from their registered email address to <a href='mailto:sales@gotranslogistics.in'>sales@gotranslogistics.in</a>. After verifying the wallet balance and the customer's request, TruckCentral will arrange to issue a refund via check or RTGS within 15 working days. Please note that this refund does not apply to any promotional amounts added by Lobb, as these can only be used for transactions or monthly subscription fees.</p>


                <h2 className="terms-conditions-paragraph">Annexure A</h2>
                <p className="terms-conditions-paragraph"><b>Charges (Rs.)Device Subscription - Monthly</b> Rs. 800 / Month + Service Charge as applicable</p>

                <p className="terms-conditions-paragraph"><b>‍Transaction Fee – Trucker </b>5% of freight value + Service Charge as applicable</p>

                <p className="terms-conditions-paragraph"><b>Contact Details</b> Office no 1A, 3rd floor, 109/A/19 Girish Ghosh Road (Belur), Howrah 711202, West-Bengal</p>
                <p className="terms-conditions-paragraph">Phone: +91 9693094957</p>
                <p className="terms-conditions-paragraph">Email: <a href='mailto:sales@gotranslogistics.in'>sales@gotranslogistics.in</a></p>
            </div>
        </motion.div>
    );
}

export default TermsConditions;
