import React, { useState, useEffect, useRef } from 'react';
import TransporterVideo from '../../assets/video/transporter.mp4';
import TruckGif from '../../assets/video/truck gif.gif';
import '../css/Transporters.css';
import { motion } from 'framer-motion';

const cities = [
    "Ahmedabad", "Aizawl", "Akola", "Amalner", "Amravati", "Amritsar", "Anand", "Anantapur",
    "Anakapalle", "Aurangabad", "Azamgarh", "Baharampur", "Bagaha", "Balaghat", "Balurghat",
    "Baleshwar Town", "Barnala", "Baramula", "Baripada Town", "Barmer", "Bharatpur", "Bharuch",
    "Bhilai Nagar", "Bhilai", "Bhilwara", "Bhopal", "Bhuj", "Bhagalpur", "Bhadrak", "Bilaspur",
    "Bina", "Biraul", "Bokaro Steel City", "Bombay", "Brahmapur", "Chandausi", "Chandigarh",
    "Chilakaluripet", "Chittoor", "Coimbatore", "Cuttack", "Deesa", "Deoghar", "Dhanbad",
    "Dantewada", "Darbhanga", "Dimapur", "Dibrugarh", "Durg", "Dhule", "Dharmavaram", "Etawah",
    "Firozabad", "Firozpur", "Faridabad", "Faridkot", "Ganjbasoda", "Gandhinagar", "Guntur",
    "Gondia", "Gulbarga", "Gurgaon", "Guwahati", "Habra", "Hajipur", "Haldwani-cum-Kathgodam",
    "Hansi", "Hisar", "Hazaribag", "Hugli-Chinsurah", "Ichalkaranji", "Imphal", "Indore",
    "Jabalpur", "Jaisalmer", "Jammu", "Jamshedpur", "Jalgaon", "Jalpaiguri", "Jalandhar",
    "Jhansi", "Jhumri Tilaiya", "Jind", "Jorhat", "Jodhpur", "Kadapa", "Kakinada", "Kalyan-Dombivali",
    "Kamptee", "Kancheepuram", "Karimnagar", "Kashipur", "Kolkata", "Kolar", "Korba", "Kothagudem",
    "Kurnool", "Kozhikode", "Latur", "Lakhisarai", "Lakhimpur", "Ludhiana", "Loni", "Lucknow",
    "Madhubani", "Madurai", "Mahabubnagar", "Maharajganj", "Mangalore", "Mysore", "Madanapalle",
    "Malerkotla", "Mandya", "Mangaluru", "Mandsaur", "Marmagao", "Medinipur", "Muzaffarpur",
    "Moradabad", "Morvi", "Nagapattinam", "Nagda", "Nagpur", "Nanded", "Nanded-Waghala", "Nashik",
    "Nellore", "Neyveli (TS)", "New Delhi", "Noida", "Nagarcoil", "Navi Mumbai", "Ongole", "Orai",
    "Palakkad", "Pali", "Panchkula", "Panaji", "Panipat", "Parbhani", "Patan", "Pathankot",
    "Pudukkottai", "Purnia", "Purulia", "Raayachuru", "Rae Bareli", "Rajkot", "Rajampet",
    "Rajasthan", "Rajnandgaon", "Ramgarh", "Rampur", "Ranchi", "Ratlam", "Rewari", "Rudrapur",
    "Salem", "Sambalpur", "Sambhal", "Sangli", "Satna", "Saurashtra", "Siliguri", "Srinagar",
    "Shillong", "Shimla", "Shivamogga", "Shivpuri", "Sitapur", "Sitamarhi", "Suryapet",
    "Tadepalligudem", "Tadpatri", "Theni Allinagaram", "Thiruvananthapuram", "Tiruchirappalli",
    "Tirupati", "Tumkur", "Udhagamandalam", "Udaipur", "Udupi", "Ujjain", "Vadodara", "Valsad",
    "Varanasi", "Vapi", "Vellore", "Viluppuram", "Visakhapatnam", "Warangal", "Washim",
    "Wardha", "Yavatmal", "Yemmiganur", "Yamunanagar", "Zirakpur"
];

const vehicleTypes = [
    "22 Ft Container", "24 Ft Container", "32 Ft SXL Container", "32 Ft MXL Container", "34 Ft Container"
];

const Transporters = () => {
    const [transportName, setTransportName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailId, setEmailId] = useState('');
    const [fromCity, setFromCity] = useState('');
    const [toCity, setToCity] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [tonnage, setTonnage] = useState('');
    const [targetRate, setTargetRate] = useState('');

    const [fromCityOptions, setFromCityOptions] = useState(cities);
    const [toCityOptions, setToCityOptions] = useState(cities);
    const [vehicleTypeOptions, setVehicleTypeOptions] = useState(vehicleTypes);

    const [showFromCityDropdown, setShowFromCityDropdown] = useState(false);
    const [showToCityDropdown, setShowToCityDropdown] = useState(false);
    const [showVehicleTypeDropdown, setShowVehicleTypeDropdown] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isError, setIsError] = useState(false);  // State for tracking errors
    const [buttonText, setButtonText] = useState('Submit'); // State for button text
    const [isSubmitting, setIsSubmitting] = useState(false); // State to disable the button during submission

    const fromCityDropdownRef = useRef(null);
    const toCityDropdownRef = useRef(null);
    const vehicleTypeDropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                fromCityDropdownRef.current && !fromCityDropdownRef.current.contains(event.target) &&
                toCityDropdownRef.current && !toCityDropdownRef.current.contains(event.target) &&
                vehicleTypeDropdownRef.current && !vehicleTypeDropdownRef.current.contains(event.target)
            ) {
                setShowFromCityDropdown(false);
                setShowToCityDropdown(false);
                setShowVehicleTypeDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCitySearch = (input, setOptions, options, setShowDropdown) => {
        const filteredOptions = options.filter(option => option.toLowerCase().includes(input.toLowerCase()));
        setOptions(filteredOptions);
        setShowDropdown(true);
    };

    const handleOptionSelect = (option, setOption, setShowDropdown) => {
        setOption(option);
        setShowDropdown(false); // Close the dropdown
    };

    const closeAllDropdowns = () => {
        setShowFromCityDropdown(false);
        setShowToCityDropdown(false);
        setShowVehicleTypeDropdown(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonText("Sending..."); // Update button text to "Sending..."
        setIsSubmitting(true); // Disable button during submission

        const bookingData = {
            transportName,
            phoneNumber,
            emailId,
            fromCity,
            toCity,
            vehicleType,
            tonnage,
            targetRate,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_POST_URL}/api/bookings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bookingData),
            });

            if (response.ok) {
                setModalMessage('Your booking has been submitted successfully!');
                setIsError(false);  // Success, no error
                setShowModal(true);
                resetForm();
            } else {
                setModalMessage('Failed to submit booking.');
                setIsError(true);  // Error occurred
                setShowModal(true);
            }
        } catch (error) {
            console.error('Error submitting booking:', error);
            setModalMessage('An error occurred while submitting your booking.');
            setIsError(true);  // Error occurred
            setShowModal(true);
        } finally {
            setButtonText("Submit"); // Reset button text to "Submit"
            setIsSubmitting(false); // Re-enable button
        }
    };

    const resetForm = () => {
        setTransportName('');
        setPhoneNumber('');
        setEmailId('');
        setFromCity('');
        setToCity('');
        setVehicleType('');
        setTonnage('');
        setTargetRate('');
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <motion.div className='transporter-main-containers' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0, transition: { duration: 0.2 } }}>
            <div className='transporter-video-container'>
                <video autoPlay loop muted playsInline>
                    <source src={TransporterVideo} type="video/mp4" />
                </video>
            </div>
            <h1 className="heading-one animate__animated animate__fadeInUp">Your Cargo,</h1>
            <h1 className="heading-two animate__animated animate__fadeInUp">Our Expertise</h1>
            <p className="paragraph">Utilize our extensive network of certified truckers to transport your goods with full real-time tracking and dedicated 24/7 customer support.</p>

            <div className="transporter-button-load">
                <a href='#transporters' className="transporter-book-truck">
                    <span>Book a Truck</span>
                </a>
            </div>

            <section className="transporter-container" id="transporters">
                <div className="transporter-form-container">
                    <h2 className="transporter-title">Book Your Truck</h2>
                    <form onSubmit={handleSubmit} className="transporter-form-main">
                        <div className="transporter-form-group">
                            <input
                                className="transporter-input"
                                id="transportName"
                                type="text"
                                placeholder="Transport Name"
                                value={transportName}
                                onChange={(e) => setTransportName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="transporter-form-group">
                            <input
                                className="transporter-input"
                                id="phoneNumber"
                                type="tel"
                                placeholder="Phone Number"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                minLength={10}
                                maxLength={10}
                                required
                            />
                        </div>
                        <div className="transporter-form-group">
                            <input
                                className="transporter-input"
                                id="emailId"
                                type="email"
                                placeholder="Email Address"
                                value={emailId}
                                onChange={(e) => setEmailId(e.target.value)}
                                required
                            />
                        </div>

                        {/* From City Searchable Dropdown */}
                        <div className="transporter-form-group searchable-select" ref={fromCityDropdownRef}>
                            <input
                                className="dropdown-input"
                                type="text"
                                placeholder="From City"
                                value={fromCity}
                                onChange={(e) => {
                                    setFromCity(e.target.value);
                                    handleCitySearch(e.target.value, setFromCityOptions, cities, setShowFromCityDropdown);
                                }}
                                onFocus={() => {
                                    closeAllDropdowns();
                                    setShowFromCityDropdown(true);
                                }}
                                required
                            />
                            {showFromCityDropdown && (
                                <div className="dropdown-list">
                                    {fromCityOptions.map(city => (
                                        <div
                                            key={city}
                                            className="dropdown-item"
                                            onClick={() => handleOptionSelect(city, setFromCity, setShowFromCityDropdown)}
                                        >
                                            {city}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* To City Searchable Dropdown */}
                        <div className="transporter-form-group searchable-select" ref={toCityDropdownRef}>
                            <input
                                className="dropdown-input"
                                type="text"
                                placeholder="To City"
                                value={toCity}
                                onChange={(e) => {
                                    setToCity(e.target.value);
                                    handleCitySearch(e.target.value, setToCityOptions, cities, setShowToCityDropdown);
                                }}
                                onFocus={() => {
                                    closeAllDropdowns();
                                    setShowToCityDropdown(true);
                                }}
                                required
                            />
                            {showToCityDropdown && (
                                <div className="dropdown-list">
                                    {toCityOptions.map(city => (
                                        <div
                                            key={city}
                                            className="dropdown-item"
                                            onClick={() => handleOptionSelect(city, setToCity, setShowToCityDropdown)}
                                        >
                                            {city}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        {/* Vehicle Type Searchable Dropdown */}
                        <div className="transporter-form-group searchable-select" ref={vehicleTypeDropdownRef}>
                            <input
                                className="dropdown-input"
                                type="text"
                                placeholder="Select Vehicle Type"
                                value={vehicleType}
                                onChange={(e) => {
                                    setVehicleType(e.target.value);
                                    handleCitySearch(e.target.value, setVehicleTypeOptions, vehicleTypes, setShowVehicleTypeDropdown);
                                }}
                                onFocus={() => {
                                    closeAllDropdowns();
                                    setShowVehicleTypeDropdown(true);
                                }}
                                required
                            />
                            {showVehicleTypeDropdown && (
                                <div className="dropdown-list">
                                    {vehicleTypeOptions.map(type => (
                                        <div
                                            key={type}
                                            className="dropdown-item"
                                            onClick={() => handleOptionSelect(type, setVehicleType, setShowVehicleTypeDropdown)}
                                        >
                                            {type}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                        <div className="transporter-form-group">
                            <input
                                className="transporter-input"
                                id="tonnage"
                                type="number"
                                placeholder="Tonnage"
                                value={tonnage || ''}
                                onChange={(e) => setTonnage(e.target.value)}
                                required
                                min={0}
                                max={25}
                            />
                        </div>
                        <div className="transporter-form-group">
                            <input
                                className="transporter-input"
                                id="targetRate"
                                type="text"
                                placeholder="Target Rate (INR)"
                                value={targetRate || ''}
                                onChange={(e) => setTargetRate(e.target.value)}
                                required
                            />
                        </div>
                        <button className="transporter-button" type="submit" disabled={isSubmitting}><span>{buttonText}</span></button>
                    </form>
                    <div className='gif'>
                        <img src={TruckGif} alt="Truck GIF" className='truck-gif'></img>
                    </div>

                    {/* Modal for success or error message */}
                    {showModal && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <h3>{isError ? 'Error' : 'Success'}</h3>
                                <p>{modalMessage}</p>
                                <button className="modal-close-button" onClick={handleCloseModal}>Close</button>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </motion.div>
    );
};

export default Transporters;