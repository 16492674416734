// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ComingSoon.css */

.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 98vw;
    background-color: -var(--body-color);
    color: black;
    text-align: center;
  }
  
  .coming-soon-text {
    font-family: 'Arial', sans-serif;
    font-size: 3vw; /* Responsive font size based on viewport width */
  }
  
  .dot {
    animation: blink 1s infinite;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  /* Media Queries for Extra Responsiveness */
  
  @media (max-width: 768px) {
    .coming-soon-text {
      font-size: 6vw; /* Increase font size for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .coming-soon-text {
      font-size: 8vw; /* Further increase font size for very small screens */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/css/ComingSoon.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,oCAAoC;IACpC,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,gCAAgC;IAChC,cAAc,EAAE,iDAAiD;EACnE;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,KAAK,UAAU,EAAE;IACjB,MAAM,UAAU,EAAE;IAClB,OAAO,UAAU,EAAE;EACrB;;EAEA,2CAA2C;;EAE3C;IACE;MACE,cAAc,EAAE,2CAA2C;IAC7D;EACF;;EAEA;IACE;MACE,cAAc,EAAE,sDAAsD;IACxE;EACF","sourcesContent":["/* ComingSoon.css */\n\n.coming-soon-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    width: 98vw;\n    background-color: -var(--body-color);\n    color: black;\n    text-align: center;\n  }\n  \n  .coming-soon-text {\n    font-family: 'Arial', sans-serif;\n    font-size: 3vw; /* Responsive font size based on viewport width */\n  }\n  \n  .dot {\n    animation: blink 1s infinite;\n  }\n  \n  .dot:nth-child(2) {\n    animation-delay: 0.2s;\n  }\n  \n  .dot:nth-child(3) {\n    animation-delay: 0.4s;\n  }\n  \n  @keyframes blink {\n    0% { opacity: 0; }\n    50% { opacity: 1; }\n    100% { opacity: 0; }\n  }\n  \n  /* Media Queries for Extra Responsiveness */\n  \n  @media (max-width: 768px) {\n    .coming-soon-text {\n      font-size: 6vw; /* Increase font size for smaller screens */\n    }\n  }\n  \n  @media (max-width: 480px) {\n    .coming-soon-text {\n      font-size: 8vw; /* Further increase font size for very small screens */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
